import React, { useEffect, useState } from 'react';
import MainLayout from './mainLayout';
import API from '../api';
import Loader from '../components/loader';
import { useData } from '../hooks/useContext';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { CONFIG } from '../config';
import OrganizationPageMap from '../components/organizationPageMap';
import { IFullOrganization, IUseData, IPerson, ILocation, IPartialOrganization, ICategory } from '../interfaces';
import organizationsService from '../services/organizations.service';
import ArrowRightSVG from '../components/SVG/arrowRightSVG';
import ArrowLeftSVG from '../components/SVG/arrowLeftSVG';
import MenuOrganizationsSVG from '../components/SVG/menuSVG/menuOrganizations';
import MenuLocationsSVG from '../components/SVG/menuSVG/menuLocations';
import MenuPersonsSVG from '../components/SVG/menuSVG/menuPersons';

const OrganizationPage = ({ match }: RouteComponentProps<{ organizationId: string }>) => {
  const [organization, setOrganization] = useState<IFullOrganization>();
  const [activeImg, setActiveImg] = useState<string>();
  const { setPage, organizations, locations, persons, categories, theme } = useData() as IUseData;

  async function getOrg(id: string) {
    const orgData = await organizationsService.getById(id);
    setOrganization(orgData);
    if (orgData?.gallery && orgData?.gallery.length !== 0) {
      setActiveImg(orgData.gallery[0]);
    } else {
      setActiveImg('нет изображения$/IMG/no_img.jpg');
    }
    document.title = orgData.name;
  }

  function getLocation(location: string): ILocation | undefined {
    for (let i in locations) {
      if (locations[i]._id === location) {
        return locations[i];
      }
    }
  }

  function getPerson(person: string): IPerson | undefined {
    for (let i in persons) {
      if (persons[i]._id === person) {
        return persons[i];
      }
    }
  }

  function getOrganization(org: string): IPartialOrganization | undefined {
    for (let i in organizations) {
      if (organizations[i]._id === org) {
        return organizations[i];
      }
    }
  }

  function getCategory(cat: string): ICategory | undefined {
    for (let group in categories) {
      for (let category in categories[group])
        if (categories[group][category]._id === cat) {
          return categories[group][category];
        }
    }
  }

  function getCategoriesAsObj(cats: string[]) {
    const newCats: any = {};
    cats.forEach((cat: string) => {
      const newCat: any = getCategory(cat);
      if (!newCats[newCat?.group]) {
        newCats[newCat?.group] = [];
      }
      newCats[newCat?.group].push(newCat.name);
    });
    return newCats;
  }

  function changeImg(order: string) {
    if (order === 'asc' && organization?.gallery?.indexOf(activeImg!)! < organization?.gallery?.length! - 1) {
      setActiveImg(organization?.gallery![organization?.gallery?.indexOf(activeImg!)! + 1]);
    }
    if (order === 'desc' && organization?.gallery?.indexOf(activeImg!)! > 0) {
      setActiveImg(organization?.gallery![organization?.gallery?.indexOf(activeImg!)! - 1]);
    }
  }

  useEffect(() => {
    setOrganization(undefined);
    getOrg(match.params.organizationId);
  }, [match.params.organizationId]);

  useEffect(() => {
    setPage('organizations');
    setTimeout(() => {
      Array.from(document.getElementsByClassName('ymaps-2-1-79-copyright')).forEach((el) => el.remove());
    }, 700);
  }, []);

  return (
    <MainLayout>
      {/* <div className="m-auto w-full 2xl:w-4/5 h-100 md:px-12 flex flex-col px-2"> */}
      {organization && locations && persons ? (
        <>
          {/* <h2 className={"w-fit text-center text-3xl pb-1 px-2 font-semibold border-b-2 self-center" + theme.redBorder}>{organization.name}</h2> */}

          <div className="flex flex-col lg:flex-row mt-10">
            {organization.gallery ? (
              <div
                className={
                  'w-full sm:w-4/5 md:w-3/5 lg:w-1/3 md:me-10 flex flex-col h-fit self-center lg:self-start p-5 rounded-[40px]' +
                  theme.secondary
                }
              >
                <img
                  src={activeImg?.includes('$') ? activeImg.split('$')[1] : activeImg}
                  className=" w-full top-0 bottom-0 m-auto rounded-[20px]"
                  alt={activeImg?.includes('$') ? activeImg.split('$')[0] : 'Изображение ' + organization.name}
                />
                {organization.gallery.length !== 0 ? (
                  <div className="text-center rounded-[20px] bg-white mt-5 px-[10px] py-5">
                    {activeImg?.includes('$') ? activeImg.split('$')[0] : 'Изображение ' + organization.name}
                  </div>
                ) : null}
                {organization.gallery.length > 1 ? (
                  <div className="flex flex-row overflow-auto lg:justify-between h-fit self-center mt-5">
                    <button
                      className={
                        activeImg === organization.gallery[0]
                          ? 'text-xl h-8 w-8 me-2 p-2 rounded self-center cursor-not-allowed' + theme.active
                          : 'text-xl h-8 w-8 me-2 p-2 rounded self-center' + theme.active
                      }
                      onClick={() => changeImg('desc')}
                      title="Предыдущее изображение"
                    >
                      <ArrowLeftSVG />
                    </button>
                    <div className="flex flex-row overflow-auto lg:justify-between h-fit self-center">
                      {organization.gallery.map((img: string) => (
                        <img
                          key={img}
                          src={img?.includes('$') ? img.split('$')[1] : img}
                          className={
                            activeImg === img
                              ? 'h-28 w-36 mx-1 my-2 border-2 rounded-[10px]' + theme.redBorder
                              : 'h-28 w-36 mx-1 my-2  rounded-[10px]'
                          }
                          onClick={() => setActiveImg(img)}
                          alt={
                            img?.includes('$')
                              ? img.split('$')[0]
                              : 'Изображение ' + organization.gallery?.indexOf(img) + ' ' + organization.name
                          }
                        />
                      ))}
                    </div>
                    <button
                      className={
                        activeImg === organization.gallery[organization.gallery.length - 1]
                          ? 'text-xl h-8 w-8 ms-2 p-2 rounded self-center cursor-not-allowed' + theme.active
                          : 'text-xl h-8 w-8 ms-2 p-2 rounded self-center' + theme.active
                      }
                      onClick={() => changeImg('asc')}
                      title="Следующее изображение"
                    >
                      <ArrowRightSVG />
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}

            <main
              className={
                organization.gallery && organization.gallery.length !== 0
                  ? 'w-full lg:w-2/3 flex flex-col md:p-10 p-5 mt-5 md:mt-0 border-2 rounded-[40px] h-fit' +
                    theme.redBorder
                  : 'w-full flex flex-col border-2 md:p-10 p-5 mt-5 md:mt-0 rounded-[40px] h-fit' + theme.redBorder
              }
            >
              {/* <div className="flex flex-row"> */}
              <h2 className={'w-fit text-3xl font-semibold' + theme.redBorder}>{organization.name}</h2>

              {organization.categories ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.CATEGORIES !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.ORGANIZATION_PAGE.CATEGORIES}</h3>
                  ) : null}
                  <div className="mt-5">
                    {Object.keys(getCategoriesAsObj(organization.categories)).map((group) => (
                      <div key={group} className="">
                        <span className="font-semibold groupname">{group + ': '}</span>
                        {getCategoriesAsObj(organization.categories)[group].join(', ')}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {/* {organization.categories ? (
                  <div className="lg:px-3 pb-3">
                    {CONFIG.ORGANIZATION_PAGE.CATEGORIES !== '' ? (
                      <h3 className={'w-fit font-semibold border-b-2 px-1' + theme.redBorder2}>
                        {CONFIG.ORGANIZATION_PAGE.CATEGORIES}
                      </h3>
                    ) : null}
                    {Object.keys(getCategoriesAsObj(organization.categories)).map((group) => (
                      <div key={group}>
                        <b>{group}: </b>
                        {getCategoriesAsObj(organization.categories)[group].join(', ')}
                      </div>
                    ))}
                  </div>
                ) : null} */}

              {organization.alt_names && organization.alt_names.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.ALT_NAMES !== '' ? (
                    <h3 className={'w-fit border-b-2 mb-5' + theme.redBorder2}>{CONFIG.ORGANIZATION_PAGE.ALT_NAMES}</h3>
                  ) : null}
                  {organization.alt_names.map((altName: string) => (
                    <div className="" key={altName}>
                      {altName}
                    </div>
                  ))}
                </div>
              ) : null}

              {organization.info ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.INFO !== '' ? (
                    <h3 className={'w-fit border-b-2' + theme.redBorder2}>{CONFIG.ORGANIZATION_PAGE.INFO}</h3>
                  ) : null}
                  <div className="mt-5">
                    {organization.info.split('\n').map((subStr, i) => (
                      <span key={i}>
                        {subStr}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}

              {organization.locations ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.LOCATIONS !== '' ? (
                    <h3 className={'w-fit border-b-2 mb-5 pb-1 flex flex-row' + theme.redBorder2}>
                      <MenuLocationsSVG color="#141B34" />
                      <span className="me-1"></span>
                      {CONFIG.ORGANIZATION_PAGE.LOCATIONS}
                    </h3>
                  ) : null}
                  {organization.locations.map((location: string) =>
                    CONFIG.SHOW_LOCATIONS ? (
                      <Link key={location} to={'/locations/' + location} className="block w-fit">
                        {getLocation(location)?.adress}
                      </Link>
                    ) : (
                      <div className="" key={location}>
                        {getLocation(location)?.adress}
                      </div>
                    )
                  )}
                </div>
              ) : null}

              {organization.persons && organization.persons.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.PERSONS !== '' ? (
                    <h3 className={'w-fit border-b-2 pb-1 mb-5 flex flex-row' + theme.redBorder2}>
                      <MenuPersonsSVG color="#141B34" />
                      <span className="me-1"></span>
                      {CONFIG.ORGANIZATION_PAGE.PERSONS}
                    </h3>
                  ) : null}
                  {organization.persons.map((person: string) =>
                    CONFIG.SHOW_PERSONS ? (
                      <Link key={person} to={'/persons/' + person} className="block w-fit">
                        {getPerson(person)?.name}
                      </Link>
                    ) : (
                      <div className="" key={person}>
                        {getPerson(person)?.name}
                      </div>
                    )
                  )}
                </div>
              ) : null}

              {organization.organizations && organization.organizations.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.ORGANIZATIONS !== '' ? (
                    <h3 className={'w-fit border-b-2 mb-5 pb-1 flex flex-row' + theme.redBorder2}>
                      <MenuOrganizationsSVG color="#141B34" />
                      <span className="me-1"></span>
                      {CONFIG.ORGANIZATION_PAGE.ORGANIZATIONS}
                    </h3>
                  ) : null}
                  {organization.organizations.map((org: string) => (
                    <Link key={org} to={'/organizations/' + org} className="block w-fit">
                      {getOrganization(org)?.name}
                    </Link>
                  ))}
                </div>
              ) : null}

              {organization.links && organization.links.length !== 0 ? (
                <div className="mt-10">
                  {CONFIG.ORGANIZATION_PAGE.LINKS !== '' ? (
                    <h3 className={'w-fit border-b-2 mb-5' + theme.redBorder2}>{CONFIG.ORGANIZATION_PAGE.LINKS}</h3>
                  ) : null}
                  {organization.links.map((link: string) =>
                    link.includes('$') ? (
                      <a key={link} href={link.split('$')[1]} className=" block" target="_blank">
                        {link.split('$')[0] !== '' ? link.split('$')[0] : link.split('$')[1]}
                      </a>
                    ) : (
                      <div className="" key={link}>
                        {link}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </main>
          </div>

          {organization.locations && organization.locations.length !== 0 ? (
            <div className="w-full mt-5 mb-10">
              <OrganizationPageMap locations={organization.locations.map((loc) => getLocation(loc))} />
            </div>
          ) : null}
        </>
      ) : (
        <Loader title={'Загрузка информации об организации...'} />
      )}
      {/* </div> */}
    </MainLayout>
  );
};

export default OrganizationPage;
